import React, { useState } from 'react';
import './App.css';

import hero from './assets/hero.png';
import logo from './assets/logo.png';
import coincapIcon from './assets/coinmarketcap-1.svg';

// Import your character images
import aboutCharacter from './assets/aboutus.png';
import howToBuyCharacter from './assets/home.png';
import tokenomicsCharacter from './assets/G22.png';
import roadmapCharacter from './assets/G21.png';


const App = () => {
  return (
    <div className="App">
      <Header />
      <Hero />
      <HowToBuy />
      <Tokenomics />
      <Roadmap />
      <About />
      <Footer />
    </div>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li><a href="#home">HOME</a></li>
          <li><a href="#about">ABOUT</a></li>
          <li><a href="#how-to-buy">HOW TO BUY</a></li>
          <li><a href="#tokenomics">TOKENOMICS</a></li>
          <li><a href="#roadmap">ROADMAP</a></li>
        </ul>
      </nav>
    </header>
  );
};

const Hero = () => {
  return (
    <section className="hero">
      <img src={hero} alt="Hero" className="hero-image" />
      <div className="hero-overlay">
        <h1>DBF</h1>
        <p>The most meme-worthy memecoin in existence. It's time for DBF to take the throne.</p>
        <div className="social-icons">
          {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a> */}
          <a href="https://www.facebook.com/profile.php?id=61560418683373" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://t.me/+objgM3MKW5BjMWFk" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a>
          <a href="https://coinmarketcap.com/community/profile/zek0ufc9qftm/" target="_blank" rel="noopener noreferrer">
            <img src={coincapIcon} alt="CoinCap" className="custom-icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-content">
      <img src={aboutCharacter} alt="About Us" className="heading-icon" />
        <h2> About Us</h2>
        <div className="about-grid">
          <div className="about-card">
            <p>I am Professor OOK, PhD in Computer Science and Networking, and a Data Scientist. My goal is to raise funds through tokenization and build a team specializing in AI and quantum computing, aiming to develop a quantum-proof blockchain-based internet network.</p>
          </div>
          <div className="about-card">
            <p>Investing in BDF will prove to be a valuable decision, as your resources will be well-utilized and yield positive outcomes.</p>
          </div>
          <div className="about-card">
            <p>With a team of passionate experts, we aim to push the boundaries of what's possible and deliver cutting-edge technology to our users.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const HowToBuy = () => {
  return (
    <section id="how-to-buy" className="how-to-buy-section">
      <div className="how-to-buy-content">
      <img src={howToBuyCharacter} alt="How to Buy" className="heading-icon" />
        <h2> How to Buy</h2>
        <p>Buying Token is simple and straightforward just swap any token to DBF</p>
        <div className="steps-container">
          <div className="step-card">
            <h3>UNISWAP</h3>
            <a href="https://app.uniswap.org/explore/tokens/polygon/0x5b873c704448782a1e9d8f1422610d8e7903177d" target="_blank" rel="noopener noreferrer">Buy</a>
            <h3>DEXTOOLS</h3>
            <a href="https://www.dextools.io/app/en/polygon/pair-explorer/0xf5899694aac5c45213a1f346233217f22039e855?t=1717331859929" target="_blank" rel="noopener noreferrer">Buy</a>
          </div>
        </div>
      </div>
    </section>
  );
};

const Tokenomics = () => {
  return (
    <section id="tokenomics" className="tokenomics-section">
      <div className="tokenomics-content">
      <img src={tokenomicsCharacter} alt="Tokenomics" className="heading-icon" />
        <h2> Tokenomics</h2>
        <p>0x5B873C704448782a1e9d8f1422610d8e7903177d</p>
        <p>Our tokenomics model ensures sustainable growth and value appreciation. Key aspects include:</p>
        <div className="tokenomics-grid">
          <div className="tokenomics-card">
            <h3>Fixed Supply 10000000000000</h3>
            <p>A limited supply of tokens to ensure scarcity and value retention.</p>
          </div>
          <div className="tokenomics-card">
            <h3>Transparent Distribution</h3>
            <p>Over time, 50-60% of the token's supply will be allocated to liquidity.</p>
          </div>
          <div className="tokenomics-card">
            <h3>NO Pump and Dump</h3>
            <p>You can convert $10 into $1 million with the right investment strategy and opportunities.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Roadmap = () => {
  return (
    <section id="roadmap" className="roadmap-section">
      <div className="roadmap-content">
      <img src={roadmapCharacter} alt="Roadmap" className="heading-icon" />
        <h2> Roadmap</h2>
        <div className="roadmap-grid">
          <div className="roadmap-card">
            <h3>Q3 2024</h3>
            <p>The most meme-worthy memecoin in existence. It's time for DBF to take the throne.</p>
          </div>
          <div className="roadmap-card">
            <h3>Q4 2024</h3>
            <p>In Q2 2024, we focused on increasing sales and enhancing liquidity to drive growth and stability for our organization.</p>
          </div>
          <div className="roadmap-card">
            <h3>Q1 2025</h3>
            <p>Expansion to new markets and establishing a global presence.</p>
          </div>
          <div className="roadmap-card">
            <h3>Q2 2025</h3>
            <p>The next big meme token is on the horizon, offering another chance for those who missed out on previous opportunities.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 DBF. All rights reserved.</p>
    </footer>
  );
};

export default App;
